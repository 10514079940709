var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-container" }, [
    _c("img", {
      staticStyle: {
        width: "150px",
        position: "absolute",
        top: "20px",
        left: "20px",
      },
      attrs: { src: require("@/assets/login/logo-white.png"), alt: "" },
    }),
    _c(
      "span",
      {
        staticStyle: {
          position: "absolute",
          bottom: "20px",
          right: "20px",
          cursor: "pointer",
          "font-size": "14px",
          color: "#959595",
        },
        on: { click: _vm.goTenantManagement },
      },
      [_vm._v("单位管理系统")]
    ),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("img", {
          staticClass: "leftImg",
          attrs: { src: require("@/assets/login/left.png"), alt: "" },
        }),
        _c(
          "el-form",
          {
            ref: "loginForm",
            staticClass: "login-form",
            attrs: {
              autoComplete: "on",
              model: _vm.loginForm,
              rules: _vm.loginRules,
              "label-position": "left",
            },
          },
          [
            _c("h3", { staticClass: "title" }, [_vm._v("量测水远传监控平台")]),
            _c(
              "p",
              { staticClass: "tips", staticStyle: { "margin-top": "5px" } },
              [_vm._v("量测水远传监控平台——企业版")]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "text-align": "left",
                  margin: "5px 0",
                  color: "#1a96d4",
                },
              },
              [_vm._v("单位名称")]
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "tenant" } },
              [
                _c("el-input", {
                  attrs: { type: "text", placeholder: "请输入单位名称" },
                  on: { input: _vm.convertToUpperCase },
                  model: {
                    value: _vm.loginForm.tenant,
                    callback: function ($$v) {
                      _vm.$set(_vm.loginForm, "tenant", $$v)
                    },
                    expression: "loginForm.tenant",
                  },
                }),
                _c("span", { staticClass: "icon iconfont icon-yonghuguanli" }),
              ],
              1
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "text-align": "left",
                  margin: "5px 0",
                  color: "#1a96d4",
                },
              },
              [_vm._v("用户名称")]
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "username" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "username",
                    type: "text",
                    autoComplete: "on",
                    placeholder: "请输入用户名称",
                  },
                  model: {
                    value: _vm.loginForm.username,
                    callback: function ($$v) {
                      _vm.$set(_vm.loginForm, "username", $$v)
                    },
                    expression: "loginForm.username",
                  },
                }),
                _c("span", {
                  staticClass: "icon iconfont icon-yonghu_zhanghao_wode",
                }),
              ],
              1
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "text-align": "left",
                  margin: "5px 0",
                  color: "#1a96d4",
                },
              },
              [_vm._v("登录密码")]
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "password" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "password",
                    type: _vm.pwdType,
                    autoComplete: "on",
                    placeholder: "请输入登录密码",
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      _vm.beforeLogin(() => {
                        _vm.handleLogin()
                      })
                    },
                  },
                  model: {
                    value: _vm.loginForm.password,
                    callback: function ($$v) {
                      _vm.$set(_vm.loginForm, "password", $$v)
                    },
                    expression: "loginForm.password",
                  },
                }),
                _c("span", {
                  class:
                    _vm.pwdType === "password"
                      ? "icon iconfont icon-xianshi_chakan"
                      : "icon iconfont icon-yincang",
                  on: { click: _vm.showPwd },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    ref: "myButton",
                    staticStyle: {
                      width: "100%",
                      background: "#4452D5",
                      "font-size": "24px",
                      height: "50px",
                    },
                    attrs: { type: "primary", loading: _vm.loading },
                    nativeOn: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.beforeLogin(() => {
                          _vm.handleLogin()
                        })
                      },
                    },
                  },
                  [_vm._v(" 登 录 ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }