<template>
  <div class="login-container">
    <img src="~@/assets/login/logo-white.png" alt="" style="width: 150px;position: absolute; top: 20px; left: 20px;">
    <span style="position: absolute;bottom: 20px;right: 20px;cursor: pointer;font-size: 14px;color: #959595;" @click="goTenantManagement">单位管理系统</span>
    <div class="content">
      <img class="leftImg" src="~@/assets/login/left.png" alt="">
      <el-form class="login-form" autoComplete="on" :model="loginForm" :rules="loginRules" ref="loginForm"
        label-position="left">
        <h3 class="title">量测水远传监控平台</h3>
        <p class="tips" style="margin-top:5px;">量测水远传监控平台——企业版</p>
        <p style="text-align: left;margin: 5px 0;color: #1a96d4;">单位名称</p>
        <el-form-item prop="tenant" >
          <el-input type="text"  v-model="loginForm.tenant" @input="convertToUpperCase" placeholder="请输入单位名称" />
          <!-- <el-select v-model="loginForm.tenant" placeholder="请选择租户" @change="tenantChange" style="width:85%;">
            <el-option v-for="item in tenants" :key="item.value" :label="item.name" :value="item.value">
            </el-option>
          </el-select> -->
          <span class="icon iconfont icon-yonghuguanli"></span>
        </el-form-item>
        <p style="text-align: left;margin: 5px 0;color: #1a96d4;">用户名称</p>
        <el-form-item prop="username">
          <el-input  name="username" type="text"  v-model="loginForm.username" autoComplete="on" placeholder="请输入用户名称" />
          <span class="icon iconfont icon-yonghu_zhanghao_wode"></span>
        </el-form-item>
        <p style="text-align: left;margin: 5px 0;color: #1a96d4;">登录密码</p>
        <el-form-item prop="password">
          <el-input name="password" :type="pwdType" @keyup.enter.native="beforeLogin(()=>{handleLogin()})" v-model="loginForm.password" autoComplete="on" placeholder="请输入登录密码"></el-input>
          <span @click="showPwd" :class="pwdType === 'password' ? 'icon iconfont icon-xianshi_chakan' : 'icon iconfont icon-yincang'"></span>
        </el-form-item>

        

        <!-- <div class="tips" v-if="isIdentityAuth">
          <router-link to="/oidcRedirect">
            <el-badge is-dot>接口服务器启用了Oauth认证，请点击这里登录</el-badge>
          </router-link>
        </div>
        <el-form-item v-else> -->
        <el-form-item>
          <el-button v-waves type="primary" style="width:100%;background:#4452D5;font-size: 24px;height: 50px;" :loading="loading" ref="myButton" @click.native.prevent="beforeLogin(()=>{handleLogin()})">
            登 录
          </el-button>
        </el-form-item>
      </el-form>
      <!-- <div class="downloadEwm_box">
        <img class="downloadEwm" src="~@/assets/login/downloadEwm.jpg" alt="">
        <span class="downloadEwm_span">远传监控平台微信小程序</span>
      </div> -->
    </div>

  </div>
</template>

<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  import waves from '@/directive/waves' // 水波纹指令
  import { removeToken } from "@/utils/auth";
  import {
    mapGetters,
    mapActions
  } from 'vuex'
  export default {
    name: 'login',
    directives: {
      waves
    },
    data() {
      const validateUsername = (rule, value, callback) => {
        if (value.length <= 0) {
          callback(new Error('用户名不能为空'))
        } else {
          callback()
        }
      }
      const validatePass = (rule, value, callback) => {
        if (value.length <= 0) {
          callback(new Error('密码不能为空'))
        } else {
          callback()
        }
      }
      return {
        // tenant: this.tenantid || '', //当前选择的租户
        tenants: [],
        // tenants: [{
        //   value: 'OpenAuthDBContext',
        //   label: '默认租户'
        // }, {
        //   value: 'ErrorId404',
        //   label: '模拟一个不存在的租户'
        // }],
        loginForm: {
          username: '',
          password: '',
          tenant: this.tenantid || '', //当前选择的租户
        },
        loginRules: {
          username: [{ required: true, trigger: 'blur', validator: validateUsername }],
          password: [{ required: true, trigger: 'blur', validator: validatePass }],
          // tenant: [{ required: true, message: '请输入单位', trigger: 'change' }]
          tenant: [
            { required: true, message: '请输入4位大写字母', trigger: 'blur' },  
            { pattern: /^[A-Z0-9]{4}$/, message: '请输入4位大写字母', trigger: 'blur' }
          ]
        },
        loading: false,
        pwdType: 'password'
      }
    },
    computed: {
      ...mapGetters([
        'isIdentityAuth',
        'tenantid'
      ])
    },
    created() {
      // this.getTenants()//获取租户列表
    },
    mounted() {
      this.loginForm.username = localStorage.getItem('userName');//读取缓存用户名
    },
    methods: {
      ...mapActions([
        'setTenantId', //
      ]),
      //大写转换
      convertToUpperCase(){
        this.loginForm.tenant = this.loginForm.tenant.toUpperCase()
      },
      //获取租户列表
      getTenants(){
        basicDataApi.loginGetTenants().then(res => {
          if(res.code == 200){
            this.tenants = res.result//全部租户
            // this.tenants = res.result.filter(i=>i.code != 'OpenAuthDBContext')//过滤默认租户
            this.tenants.forEach(i=>{
              i.value = i.code
              i.label = i.code
            })
          }else{
            this.$notify({
              message: res.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          
        })
      },
      tenantChange(e) {
        this.setTenantId(e)
      },
      showPwd() {
        if (this.pwdType === 'password') {
          this.pwdType = ''
        } else {
          this.pwdType = 'password'
        }
      },
      //登陆前校验
      beforeLogin(callback){
        this.$refs.loginForm.validate(valid => {
          if (valid) {
            var data = {
              tenant:this.loginForm.tenant
            }
            basicDataApi.beforeLogin(data).then(response => {
              if(response.code == 200){
                var code = response.result.code
                this.setTenantId(code)
                localStorage.setItem("userCode", JSON.stringify(code));//缓存用户code
              callback()
              }else{
                this.$notify({
                  message: response.message,
                  type: 'warning',
                  duration: 2000
                });
              }
            }).catch(()=>{
              
            })
          }
        })
      },
      handleLogin() {
        removeToken()
        this.$refs.loginForm.validate(valid => {
          if (valid) {
            this.loading = true
            this.$store.dispatch('Login', this.loginForm).then(() => {
              localStorage.setItem('userName', this.loginForm.username);//缓存用户名
              localStorage.setItem('password', this.loginForm.password);//缓存用户名
              // this.$store.state.username=this.loginForm.username
              // this.$store.state.password=this.loginForm.password
              this.loading = false
              setTimeout(() => {
                this.$router.push({
                  path: '/'
                })
              }, 250);
              location.reload()
            }).catch(() => {
              this.loading = false
            })
          } else {
            return false
          }
        })
      },
      //租户管理系统
      goTenantManagement(){
         //定义一个路径
      const href = this.$router.resolve({
        path: `/tenantmanagementlogin`,
        // query: { key: 需要传递的值 },
      }).href;
      //打开新的页面 到 href 这个页面
      window.open(href, "_blank");
        // this.$router.push({
        //   path: '/tenantmanagementlogin'
        // })
      },
    }
  }
</script>

<style rel="stylesheet/scss" lang="scss">
  $bg:#2d3a4b;
  $light_gray:#eee;
  $color_balck: #333;

  /* reset element-ui css */
  .login-container {
    .special .el-input{
      width: 100%;
    }
    .el-input {
      display: inline-block;
      height: 47px;
      width: 85%;

      input {
        background: transparent;
        border: 0px;
        -webkit-appearance: none;
        border-radius: 0px;
        padding: 12px 5px 12px 15px;
        color: $color_balck;
        height: 47px;

        &:-webkit-autofill {
          transition: background-color 5000s ease-in-out 0s;
        }
      }
    }

    .el-form-item {
      height: auto !important;
      margin-bottom: 15px !important;
      border-radius: 5px;
      color: #454545;

      .el-form-item__content {
        background: #fff;
        border: 1px solid rgba(223, 223, 223, 1);
      }

      &:last-child {
        padding-top: 20px;

        .el-form-item__content {
          border: none;
        }
      }
    }
  }
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
  .leftImg {
    width: 600px !important;
  }
  @media screen and (max-width: 1470px) {
    .leftImg {
      width: 700px !important;
    }
  }
  @media screen and (max-width: 1250px) {
    .leftImg {
      width: 450px !important;
    }
  }

  @media screen and (max-width: 1010px) {
    .leftImg {
      width: 380px !important;
    }
  }

  @media screen and (max-width: 940px) {
    .leftImg {
      display: block;
      width: 260px !important;
      margin: 0 auto !important;
    }
  }

  $dark_gray:#D1DFE8;

  .login-container {
    height: 100%;
    background: url('~@/assets/login/bg.png') no-repeat;
    background-color: #EBEBEA;
    background-position: 0 0;
    background-size: 62% 100%;
    text-align: center;

    &:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }

    .content {
      display: inline-block;
      vertical-align: middle;

      >img {
        width: 568px;
        margin-right: 150px;
        vertical-align: middle;
      }

      .login-form {
        display: inline-block;
        width: 400px;
        vertical-align: middle;
      }
    }

    .svg-container {
      color: $dark_gray;
      vertical-align: middle;
      width: 33px;
      display: inline-block;
      font-size: 22px;

      &_login {
        font-size: 31px;
      }
    }

    .title {
      font-size: 26px;
      font-weight: 400;
      color: #4452D5;
      margin: 0;
      text-align: left;
    }

    .tips {
      color: #959595;
      font-size: 14px;
      margin-top: 0;
      margin-bottom: 40px;
      text-align: left;
    }

    .show-pwd {
      position: absolute;
      right: 10px;
      top: 7px;
      font-size: 16px;
      color: $dark_gray;
      cursor: pointer;
      user-select: none;
      font-size: 24px;
    }
    .downloadEwm_box{
      position: absolute;
      bottom: 6%;
      left: 2%;
      min-width: 20px;
      .downloadEwm{
        width: 130px;
        height: 130px;
      }
      .downloadEwm_span{
        color: #959595;
        display: block;
      }
    }
    @media screen and (max-width: 1680px) {
      .leftImg{width: 600px !important;}
      .downloadEwm_box{
        font-size: 12px;
        .downloadEwm{
          width: 80px;
          height: 80px;
        }
      }
    }
  }
</style>